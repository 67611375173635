import { environment } from 'src/environments/environment';

const base_url = environment.API_URL;
const base_front_url = environment.BASE_URL;
const baseGateUrl = environment.GATE_URL;
const securekey = environment.SECURE_KEY;
const reCaptchaKey = environment.RECAPTCHA_KEY;
const baseBillUrl = environment.BILLER_URL;
const otlobxUrl = environment.OtlobxUrl;
const proxyUrl =  base_url + "proxy/";
const gateProxyUrl = proxyUrl + "gate/"
const sellergateProxyUrl = proxyUrl + "seller/gate"
export default {
  BASE_URL: base_url,
  BASE_FRONT_URL: base_front_url,
  BASE_GATE_URL: baseGateUrl,
  SECURE_KEY: securekey,
  getIpAddress: 'http://api.ipify.org/?format=json',
  RECAPTCHA_KEY: reCaptchaKey,
  S3_BUSINESS_LOGO: 'https://easykash-live3.s3.amazonaws.com/business',
  gate: {
    gateTrxRefNumUpdate: base_url + 'gate/TransactionProvider/update',
    gateInquireStatus: base_url + 'gate/InquireStatus',
    admin: {
      salesReport: gateProxyUrl + 'admin/salesReport',
      creditSalesReport: gateProxyUrl + 'admin/creditReport',
      cashSalesReport: gateProxyUrl + 'admin/cashReport',
      providerReconciliationReport: gateProxyUrl + 'admin/reconciliationReport',
      providerReconciliationFixStatus:
      gateProxyUrl + 'admin/reconciliationFixState',
    },
    merchant: {
      list: gateProxyUrl + 'merchant/list',
      delete: gateProxyUrl + 'merchant/delete',
      add: gateProxyUrl + 'merchant/create',
      get: gateProxyUrl + 'merchant/get',
      edit: gateProxyUrl + 'merchant/edit',
      getBusinessProfile: gateProxyUrl + 'merchant/getBusinessProfile',
      regenerateKeys: gateProxyUrl + 'merchant/regenerateKeys',
    },
    fraud: {
      module: {
        list: gateProxyUrl + 'fraudDetection/listModules',
        add: gateProxyUrl + 'fraudDetection/create-module',
        delete: gateProxyUrl + 'fraudDetection/delete-module',
        active: gateProxyUrl + 'fraudDetection/set-activeStatus',
        update: gateProxyUrl + 'fraudDetection/update-module',
        get: gateProxyUrl + 'fraudDetection/get-moduleById',
      },
      statment: {
        list: gateProxyUrl + 'fraudDetection/listStatements',
        get: gateProxyUrl + 'fraudDetection/get-statementById',
        add: gateProxyUrl + 'fraudDetection/create-statement',
        update: gateProxyUrl + 'fraudDetection/update-statement',
        delete: gateProxyUrl + 'fraudDetection/delete-statement',
      },
      CONDITIONS: {
        UPDATE: gateProxyUrl + 'fraudDetection/update-statementConditions',
      },
      GET_FACTORS_OPERATORS:
      gateProxyUrl + 'fraudDetection/get-factorsAndOperators',
    },
    providers: {
      list: gateProxyUrl + 'provider/list',
      payment_methods: gateProxyUrl + 'provider/payment_methods',
      delete: gateProxyUrl + 'provider/delete',
      add: gateProxyUrl + 'provider/create',
      get: gateProxyUrl + 'provider/get',
      edit: gateProxyUrl + 'provider/edit',
    },
    bin_provider: {
      list: gateProxyUrl + 'bin-provider/list',
      add: gateProxyUrl + 'bin-provider/create',
      get: gateProxyUrl + 'bin-provider/getMerchantProviderBins',
      edit: gateProxyUrl + 'bin-provider/edit',
      delete: gateProxyUrl + 'bin-provider/deleteByMerchantId',
      merchantProviders: gateProxyUrl + 'bin-provider/merchant/Providers',
      deleteBinRelation: gateProxyUrl + 'bin-provider/merchant/Providers/delete-bin',
    },
  },
  admin: {
    addRole: {
      createPermissions: base_url + 'admin/AddAdminRole',
      getSellerPermissions:
        base_url + 'collections/fetch/admin/AddSellerRole/permissions',
    },
    manageRoles: {
      fetchRoles: base_url + 'admin/roles/manageReport',
      fetchRoleDetails: base_url + 'admin/roles/getRoleDetails',
      updateRoleDetails: base_url + 'admin/roles/updateRoleDetails',
      getRoleDetails: base_url + 'admin/roles/getRoleDetails',
    },
    createAdmin: {
      adminCreator: base_url + 'admin/addAdmin',
      fetchAdminRoles: base_url + 'collections/fetch/admin/addAdmin/roles',
    },
    adminMangement: {
      getAdmins: base_url + 'admin/getAdminMangement',
      getAssignableAdmins: base_url + 'admin/getAssignableAdminManagement',
      getAdmin: base_url + 'admin/getAdmin',
      deleteAdmin: base_url + 'admin/deleteAdmin',
      updateAdmin: base_url + 'admin/updateAdmin',
      announce: base_url + 'admin/announcement',
      getMerchantId: base_url + 'admin/getMerchantId',
    },
    otlob_x_PostRequest: {
      get: otlobxUrl + 'admin/pending/posts',
      updatePost: otlobxUrl + 'admin/update/posts',
    },
    withdrawalRequests: {
      fetchWithdrawals: base_url + 'admin/getWithdrawalReports',
      filterWithdrawals: base_url + 'admin/getWithdrawalReports',
      changeStatus: base_url + 'admin/withdrawals/changeStatus',
      searchReport: base_url + 'admin/searchWithdrawalReports',
      getPendingRequests: base_url + 'admin/pendingRequests',
    },
    smsMarketingRequests: {
      getSmsMarketingRequests: base_url + 'admin/smsMarketingRequests',
      sendMarketingSmss: base_url + 'admin/sendMarketingSmss',
      fetchAllSellerProducts: base_url + 'admin/fetchAllSellerProducts',
    },
    makeAdjustment: {
      fetchBussines: base_url + 'collections/fetch/admin/getBussines',
      makeAdjustment: base_url + 'admin/makeAdjustment',
    },
    crm: {
      fetchBalance: base_url + 'collections/fetch/admin/getBussinesBalance',
      fetchCashbackDetails: base_url + 'admin/fetchCashbackDetails',
      saveBusinessCashbackDetails:
        base_url + 'admin/saveBusinessCashbackDetails',
      getBusinessProfile: base_url + 'admin/getBusinessProfile',
      getStatusProfile: base_url + 'admin/getStatusProfile',
      updateStatusProfile: base_url + 'admin/updtateStatusProfile',
      reAssignPaymentOptions: base_url + 'admin/reAssignPaymentOptions',
      changeBusinessStatus: base_url + 'admin/changeBusinessStatus',
      updateQassatlyInfoService: base_url + 'admin/assignMerchantToQassatly',
      updateProductBrowseStatus: base_url + 'admin/updateProductBrowseStatus',
      changeAdvancesalesStatus: base_url + 'admin/changeAdvancesalesStatus',
      updateContractStatus: base_url + 'admin/updateContractStatus',
      updateCreditCardLimit: base_url + 'admin/updateCreditCardLimit',
      updateCashWalletFees: base_url + 'admin/updateCashWalletFees',
      updateCashApiStatus: base_url + 'cash-api/setStatus',
      getCashApi: base_url + 'cash-api/getKey',
      updateCashApi: base_url + 'cash-api/updateKey',
      updateAvailableFeesPercentage:
        base_url + 'admin/updateAvailableFeesPercentage',
      updateNormalFees: base_url + 'admin/updateNormalFees',
      updateUrgentFees: base_url + 'admin/updateUrgentFees',
      updateHasContract: base_url + 'admin/updateHasContract',
      updateAdvancedSales: base_url + 'admin/advancedSalesRequests',
      updateAdminOrSellerRole: base_url + 'admin/updateAdminOrSellerRole',
      updatePendingToAvailable: base_url + 'admin/updatePendingToAvailable',
      updateAdvanceRepaymentFees: base_url + 'admin/updateAdvanceRepaymentFees',
      updateAdvancedFees: base_url + 'admin/updateAdvancedFees',
      updatePaymentOptionsFees: base_url + 'admin/updatePaymentOptionsFees',
      assignAdminToBusiness: base_url + 'admin/assignAdminToBusiness',
      deleteAssignAdminToBusiness:
        base_url + 'admin/deleteAssignAdminToBusiness',
      updateBusinessVerification:
        base_url + 'admin/updateBusinessVerifications',
      updateBusinessInfo: base_url + 'admin/updateBusinessInfo',
    },
    salesReport: {
      fetchSalesReport: base_url + 'admin/getSalesReports',
      resendMailSaleReport: base_url + 'admin/resendMailSalesReport',
      getSellerInfo: base_url + 'admin/getSellerInfo',
      updateStatus: base_url + 'admin/updateStatus',
    },
      salesFollowUpReport: {
      fetchSalesReport: base_url + 'admin/getSalesFollowUpReports',
      resendMailSaleReport: base_url + 'admin/resendMailSalesFollowUpReport',
      getSellerInfo: base_url + 'admin/getSellerInfoFollowUp',
      updateStatus: base_url + 'admin/updateStatusFollowUp',
    },
    sellersReport: {
      makeBusinessJunk: base_url + 'admin/updateBusinessToJunk',
    },
    reports: {
      products: {
        fetch: base_url + 'collections/fetch/admin/productsReport',
      },
      otlobx: {
        post_report: otlobxUrl + 'admin/report/posts',
      },
    },
    errorLogger: {
      getErrorsLooged: base_url + 'admin/getErrorsLogged',
    },
    manualPayment: {
      create: base_url + 'admin/manualPayment/create',
    }
  },
  dashboard: base_url + 'dashboard',
  permissions: base_url + 'permissions',
  getBussiness: base_url + 'getTokenBusiness',
  auth: {
    login: {
      login: base_url + 'user/login',
      forgotPassword: base_url + 'user/sendmail',
      forgotPasswordCodeInquiry: base_url + 'user/sendUserId',
      resetPassword: base_url + 'user/resetPassword',
      changePassword: base_url + 'user/changePassword',
      removeSession: base_url + 'user/sessions/removeOther'
    },
    session: {
      delete: base_url + 'user/logout',
      currentSession: base_url + 'user/session',
    },
    signup: {
      signup: base_url + 'user/register',
    },
    twoFactorAuthentication: {
      sendOtp: base_url + 'seller/2fa/otp/create',
    },
  },
  settings: {
    profile: {
      businessInfo: {
        customPaymentQR: base_url + 'seller/customPaymentQR',
        fetchCategories:
          base_url + 'collections/fetch/seller/updateBusiness/categories',
        getBusinessProfile: base_url + 'seller/getBusiness',
        updateBusinessProfile: base_url + 'seller/updateBusiness',
        validateBusinessName:
          base_url + 'collections/validate/user/register/business_name',
        postUploadedImage: base_url + 'seller/uploadImage/business',
        requestShopifyScript: base_url + 'integrations/shopify/script',
      },
      basicInfo: {
        fetchBasicUserInfo: base_url + 'user/getUserData',
        updateUserInfo: base_url + 'user/update',
        sendmailVerify: base_url + 'verify/email/send',
        sendMobileVerify: base_url + 'verify/mobile/send',
      },
    },
    customPayment: {
      buyerInfo: {
        update:
          base_url + 'seller/updateBusiness/custompayment/updatebuyerInfo',
        get: base_url + 'seller/custompayment/getbuyerInfo',
        delete: base_url + 'seller/updateBusiness/custompayment/deletebuyerInfo',
      },
    },
  },
  referals: {
    getReferalsInfo: base_url + 'referral/info',
    refCodeInquire: base_url + 'referral/inquire',
  },
  mailVerification: {
    sendLink: base_url + 'verify/email/send',
    verifyMail: base_url + 'verify/email/verify',
  },
  phoneVerified: base_url + 'verify/mobile',
  bankAccount: {
    addAccount: base_url + 'bank/addBank',
    getListOfBanks: base_url + 'banks',
    getAccounts: base_url + 'bank/getAccounts',
    deletAccount: base_url + 'bank/DeleteAccount',
    getBankAccountsRequest: base_url + 'bank/getBankRequests',
    bankAccountsRequestAction: base_url + 'bank/handleBankRequests',
  },

  userConfig: {
    getuserConfig: base_url + 'collections/fetch/table/config/find',
    updateOrCreateUserConfig:
      base_url + 'collections/fetch/table/config/updateOrCreate',
  },

  confirmPassword: base_url + 'user/confirmPassword',
  product: {
    search: base_url + 'products/search',
    browseProductTypeCategories: base_url + 'products/browseTypeCategories',
    browse: base_url + 'products/browse',
    types: base_url + 'products/browseTypes',
    category: {
      getCategoryName: base_url + 'product/getCategory',
    },
    add: {
      postProductStep: base_url + 'product/addSteps',
      createCustomPayment: base_url + 'payment/createCustomPayment',
      stepOneTemplate:  base_url + 'user/formTemplate/save',
    },
    fields: {
      paymentOptions: {
        fetch: base_url + 'collections/fetch/business/paymentOptions',
        fetchForSeller: base_url + 'paymentOptions/getAll',
        limit: base_url + 'paymentOptions/limit',
        fetchSellerPaymentOptions:
          base_url + 'collections/fetch/fetchPaymentOptionsForSeller',
        getProductPaymentGroups:
          base_url + 'paymentOptions/getProductPaymentGroups',
        getSelectedGroupOption:
          base_url + 'paymentOptions/getSelectedGroupOption',
        getPaymentGroupDetails:
          base_url + 'paymentOptions/getPaymentGroupDetails',
      },
      image: {
        upload: base_url + 'seller/uploadImage/products',
      },
      formBuilder: {
        fetchFieldTypes: base_url + 'collections/fetch/seller/fieldTypes',
      },
      sellerBalances: {
        fetchSellerBalances: base_url + 'seller/getBussinesBalance',
      },
      checkCashOutByMobile: {
        canFawryCashOutRequestbySeller:
        sellergateProxyUrl + '/payment/canFawryCashOutRequestbySeller',
      },
    },
    commons: {
      fetchProductTypeCategory:
        base_url + 'collections/fetch/productTypeCategories',
      fetchCollectionCustomers:
        base_url + 'seller/collections/customers/getAll',
      fetchSubStep: base_url + 'collections/fetch/productSubStep',
      fetchCurrencies: base_url + 'collections/fetch/getCurrencies',
    },
    view: {
      getProduct: base_url + 'collections/fetch/fPage',
      getProductCatalog: base_url + 'collections/fetch/seller/fetchAllProducts',
      getQuickCashData:
        base_url + 'collections/fetch/getQuickFawryAttemptOptions',
    },
    extraInfo: {
      getFields: base_url + 'payment/getBuyerInfo',
      submitBuyerInfo: base_url + 'payment/submitBuyerInfo',
      submitBuyerInfoFile: base_url + 'payment/uploadBuyerInfoFile',
      logPaymentError: base_url + 'payment/logFrontEndPaymentError',
      saveReceiptAsHtml: base_url + 'payment/saveReceiptAsHtml',
    },
    edit: {
      getProductFields: base_url + 'product/getproductfields',
    },
    get: {
      getProuductFiltered: base_url + 'collections/fetch/seller/filterProducts',
      stepOneTemplate:  base_url + 'user/formTemplate',
    },

    action: {
      deleteProduct: base_url + 'product/delete',
      publishProduct: base_url + 'product/publish',
      unPublishProduct: base_url + 'product/unPublish',
    },
    ViewTopProduct: {
      get: base_url + 'product/fetchProducts',
    },
    share: base_url + 'product/share',
  },
  event: {
    search: base_url + 'products/search',
    browseProductTypeCategories: base_url + 'products/browseTypeCategories',
    browse: base_url + 'events/browse',
    types: base_url + 'products/browseTypes',
    category: {
      getCategoryName: base_url + 'product/getCategory',
    }
  },
  source: base_url + 'validateSource',
  promocode: {
    createPromocode: base_url + 'promocode/createPromocode',
    getPromocode: base_url + 'promocode/getPromocode',
    editPromocode: base_url + 'promocode/editPromocode',
    fetchSellerProducts:
      base_url + 'collections/fetch/seller/fetchSellerProducts',
    applyPromocode: base_url + 'promocode/getPromocodeQuantity',
    fetchPromocodes: base_url + 'promocode/getPromocodesMangement',
    getPromocodeProducts: base_url + 'promocode/getPromocodeProducts',
    deletePromocode: base_url + 'promocode/deletePromocode',
  },
  systemPromocode: {
    add: base_url + 'admin/systemPromocode/add',
    get: base_url + 'admin/systemPromocode/get',
    edit: base_url + 'admin/systemPromocode/edit',
    list: base_url + 'admin/systemPromocode/list',
  },
  reports: {
    advanced_sales: {
      advanced_sales_report:
        base_url + 'collections/fetch/admin/fetchAdvancedSales',
      advanced_sales_cancel_request:
        base_url + 'collections/fetch/admin/cancelAdvancedSales',
    },
    trx: {
      filtertrx: base_url + 'collections/fetch/admin/filtertrx',
    },
    withdrawals: {
      fetchBalance: base_url + 'calculateBalance',
      resetWalletBalance: base_url + 'resetWalletBalance',
      withdrawalsReport:
        base_url + 'collections/fetch/seller/fetchSellerWithDrawReport',
      searchwithdrawalsReport:
        base_url + 'collections/fetch/seller/searchSellerWithDrawReport',
      withdrawRequest: base_url + 'withdraw',
      recurringWithdrawRequest: base_url + 'recurringWithdraw',
      cancelRecurringWithdawRequest: base_url + 'cancelRecurringWithdraw',
    },
    sales: {
      salesReport: base_url + 'collections/fetch/seller/fetchPaymentAttempt',
      filterSalesReport:
        base_url + 'collections/fetch/seller/filterFetchPaymentAttempt',
      filterEventSalesReport:
        base_url + 'collections/fetch/seller/fetchEventPaymentAttempt',
      filterReservationSalesReport:
        base_url + 'collections/fetch/seller/fetchReservationPaymentAttempt',
      searchSalesReport:
        base_url + 'collections/fetch/seller/searchFetchPaymentAttempt',
      fetchPaymentAttemptTickets:
        base_url + 'collections/fetch/seller/fetchPaymentAttemptTickets',
      fetchSubscriptionPaymentAttempt:
        base_url + 'collections/fetch/seller/fetchSubscriptionPaymentAttempt',
      filterBookingSalesReport:
        base_url + 'collections/fetch/seller/fetchBookingPaymentAttempt',
      filterMonthlyInvoiceSalesReport:
        base_url + 'collections/fetch/seller/fetchMonthlyInvoiceSales',
      fetchTransactionsDetailReport:
        base_url + 'collections/fetch/seller/fetchTransactionsDetail',
    },
    referrals: {
      getref: base_url + 'collections/fetch/admin/referrals',
    },
    merchant: {
      getref: base_url + 'collections/fetch/admin/merchantreport',
    },
    settlement: {
      getref: base_url + 'collections/fetch/admin/settlements',
    },
    sellers: {
      getref: base_url + 'collections/fetch/admin/filterBussines',
    },
    income: {
      report: base_url + 'collections/fetch/admin/incomeReport',
    },
    InvoiceAccounting: {
      report: base_url + 'collections/fetch/admin/invoiceAccountingReport',
    },
    statment: {
      getStatment: base_url + 'collections/fetch/seller/statmentReport',
    },

    qassatlyRepayment: {
      getqassatlyRepayment:
        base_url + 'collections/fetch/seller/qassatlyRepayment',
      callQassatlyRepayment: base_url + 'seller/updateQassatlyRepayments',
    },
    BankAccounts: base_url + 'collections/fetch/admin/sellerBanckAccounts',
    buyerInfo: {
      report: base_url + 'collections/fetch/seller/buyersInfo',
      details: base_url + 'collections/fetch/seller/buyerInfoDetails',
    },
    donation: base_url + 'collections/fetch/getDonationReport',
    sellerIncome: base_url + 'collections/fetch/admin/sellerIncomeReport',
    KYC: {
      report: base_url + 'collections/fetch/admin/kycReport',
      getBussines: base_url + 'admin/kycReport/getBussines',
      updateBussines: base_url + 'admin/kycReport/updateBussines',
    },
    biller: {
      buyerUsersReport: base_url + 'collections/fetch/admin/billingUsersReport',
    },
  },
  exchange: {
    getRate: base_url + 'exchange/rate',
    calculate: base_url + 'calculate/amount',
    calculateCustomPayment: base_url + 'calculate/customPaymentAmount',
  },
  seller: {
    getSellerRunningAdvancedSales:
      base_url + 'seller/getSellerRunningAdvancedSales',
    sendAdvancedSalesRequest: base_url + 'seller/sellerAdvanceSaleRequest',
    getRole: base_url + 'seller/getSellerRole',
    createMember: base_url + 'teams/sendRequest',
    sendToken: base_url + 'teams/getRequest',
    signupMember: base_url + 'teams/acceptRequest',
    getTeamMangement: base_url + 'teams/getTeamManagement',
    deleteMemeber: base_url + 'teams/deleteMemeber',
    getMemeber: base_url + 'teams/getMemeber',
    updateMemberRole: base_url + 'teams/updateMemeberRole',
    GET_WITHDRAWAL_ACTIVATION_STATUS: base_url + 'user/withdrawalStatus',
    getMigrationCode: base_url + 'productMigrations/get',
    UPDATE_CALLBACK_URL: base_url + 'cash-api/updateCallbackUrl',
    getActivationStep: base_url + 'seller/getActivationStep',
    setActivationStep: base_url + 'seller/setActivationStep',
    convertBalanceToAvailable: base_url + 'seller/convertBalanceToAvailable',
    getBusinessByName: base_url + 'seller/getBusinessByName',
    getBusinessByCode: base_url + 'seller/getBusinessByCode',
    verifyFacebook: base_url + 'seller/verifyFacebook',
    getFacebookVerification: base_url + 'seller/getFacebookverification',
    saveSmsMarketingRequest: base_url + 'seller/saveSmsMarketingRequest',
    resendEmail:  base_url + 'seller/resendEmail',
    collections: {
      customers: {
        getAll: base_url + 'seller/collections/customers/getAll',
        getOne: base_url + 'seller/collections/customers/getOne',
        create: base_url + 'seller/collections/customers/create',
        update: base_url + 'seller/collections/customers/update',
        processBulkCreate: base_url + 'seller/collections/customers/processBulkCreate',
        bulkCreate: base_url + 'seller/collections/customers/bulkCreate',
        processBulkCreateClaims: base_url + 'seller/collections/claims/processBulkCreate',
        bulkCreateClaims: base_url + 'seller/collections/claims/bulkCreate',
      },
    },
  },
  CreditCardRequest: {
    fetchBusinessData: base_url + 'ccrequest/getBusinessData',
    postCCRequest: base_url + 'ccrequest/saveRequest',
    updateStatus: base_url + 'ccrequest/updateStatus',
    getCCRequest: base_url + 'ccrequest/getRequest',
    fetchCreditCardReport: base_url + 'admin/creditCard/report',
    viewCreditCardRequest: base_url + 'ccrequest/viewRequest',
    requestAction: base_url + 'ccrequest/requestCreditCard',
    bulkRequestAction: base_url + 'ccrequest/bulkActivationRequests',
    uploadFile: base_url + 'ccrequest/uploadFile',
  },
  CreditCardPayment: {
    authenticatePay: base_url + 'payment/creditcardpayment/authenticate',
    directPay: base_url + 'payment/creditcardpayment/directpay',
    inquire: base_url + 'payment/creditcardpayment/inquire',
    inquireStatus: base_url + 'gate/InquireStatus',
  },
  contract: {
    fetchContractData: base_url + 'contract/getContractData',
    saveContractData: base_url + 'contract/saveContractData',
    fetchContractRequests: base_url + 'admin/contract/viewRequest',
    sign: base_url + 'admin/contract/sign',
    changeStatus: base_url + 'admin/contract/changeStatus',
  },
  PaymentOptions: {
    getAll: base_url + 'paymentOptions/getAll',
  },
  Payment: {
    refundCreditPayment: base_url + 'payment/refundCreditPayment',
    cancelCreditPayment: base_url + 'payment/cancelCreditPayment',
    cardPaymentsCallbacks: base_url + 'payment/cardPaymentsCallbacks',
  },
  Announcements: base_url + 'announcements',
  AdvertisementBanner: {
    image_upload: base_url + 'seller/uploadImage/advBanner',
    fetchAllBanners: base_url + 'admin/advertisementBanner/fetch',
    manage: base_url + 'admin/advertisementBanner/manage',
  },
  UPG: {
    requestToPay: base_url + 'upg/requestToPay',
    getUpgCallbackStatus: base_url + 'upg/callbackStatus'
  },
  BillPayments: {
    clientId: environment.billerClientId,
    image: {
      upload: base_url + 'seller/uploadImage',
    },
    billerService: {
      list: baseBillUrl + 'billerServices/getBillerServicesList',
      add: baseBillUrl + 'billerServices/createBillerService',
      get: baseBillUrl + 'billerServices/getBillerService',
      update: baseBillUrl + 'billerServices/updateBillerService',
      delete: baseBillUrl + 'billerServices/deleteBillerService',
    },
    Category: {
      report: baseBillUrl + 'categories/getCategoriesReport',
      list: baseBillUrl + 'categories/getAllCategories',
      add: baseBillUrl + 'categories/createCategory',
      delete: baseBillUrl + 'categories/deleteCategory',
      get: baseBillUrl + 'categories/getCategory',
      update: baseBillUrl + 'categories/editCategory',
    },
    Provider: {
      report: baseBillUrl + 'providers/getProvidersReport',
      list: baseBillUrl + 'providers/getAllProviders',
      add: baseBillUrl + 'providers/createProvider',
      delete: baseBillUrl + 'providers/deleteProvider',
      get: baseBillUrl + 'providers/getProvider',
      update: baseBillUrl + 'providers/editProvider',
    },
    Service: {
      report: baseBillUrl + 'services/getServicesReport',
      list: baseBillUrl + 'services/getAllServices',
      add: baseBillUrl + 'services/createService',
      delete: baseBillUrl + 'services/deleteService',
      get: baseBillUrl + 'services/getService',
      update: baseBillUrl + 'services/editService',
    },
    CashBack: {
      getOrCreate: baseBillUrl + 'cashback/getCashback',
      update: baseBillUrl + 'cashback/updateCashback',
      create: baseBillUrl + 'cashback/create',
    },
    Biller_Services: {
      listIncludeProviderAndCategory:
        baseBillUrl + 'billerServices/getServices',
      serviceInputParameterList:
        baseBillUrl + 'billerServices/serviceInputParameterList',
    },
    BILLER_SERVICE: {
      priceValidation: baseBillUrl + 'billerServices/price/',
      getPrice: baseBillUrl + 'billerServices/price/',
    },
    Transaction: {
      inquire: baseBillUrl + 'billerServices/transactionInquiry',
      nonInquire: baseBillUrl + 'billerServices/transaction',
      payWithBalance: base_url + 'payment/payWithAvailableBlance',
      billCreditCard: base_url + 'seller/payment/payWithCreditCard',
      allTempTransactionsReport:
        baseBillUrl + 'transactions/report/tempTransactionsReport',
      TempTransactionReportUser:
        base_url + 'admin/biller/report/tempTransactionsReport',
    },
    Limits: {
      get: baseBillUrl + 'limits/get',
      update: baseBillUrl + 'limits/update',
    },
  },
  VALU: {
    inquire: base_url + 'valu/inquire',
  },
  Website: {
    contact: base_url + 'contact',
  },
  Organization: {
    get: base_url + 'seller/organization/get',
    add: base_url + 'seller/organization/add',
    assignMember: base_url + 'seller/organization/assignMember',
  },
  Aman_Installment:{
    Send_OTP: base_url +'aman/installments/sendOtp',
    Aman_Payment : base_url + 'aman/installments/pay'
  },
  Mogo:{
    selectPlan: base_url +'mogo/selectPlan',
    confirmTransaction: base_url + 'mogo/confirmTransaction'
  },
  Contact:{
    sendOtp: base_url +'contact/sendOTP',
    pay: base_url + 'contact/pay'
  },
  TrxReceipt: {
    get:  base_url + "payment/receipt",
  }
};

export const PAYMENT = {
  inquireTrx: `${base_url}mobile/buyer/payment/inquirePayment`,
};
