import { Component, OnInit, ChangeDetectorRef, Input } from '@angular/core';
import { FormControl, FormBuilder, FormArray, AbstractControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FetchPaymentOptionsForSellerService } from './fetch-payment-options-for-seller.service';
import { BaseField } from '../../baseField';
import { AuthService } from 'src/app/shared/services/auth.service';
import { FetchPAymentGroupForSeller } from 'src/app/shared/services/fetch-payment-groups-for-seller.service';
import { PaymentOptionBox } from 'src/app/dashboard/seller/payment-pages/components/basic-info/components/basic-info/types';
import { BasicStepOneTemplateService } from 'src/app/dashboard/seller/payment-pages/components/basic-info/services/basic-step-one-template.service';

@Component({
  selector: 'app-payment-options',
  templateUrl: './payment-options.component.html',
  styleUrls: ['./payment-options.component.scss'],
})
export class PaymentOptionsComponent extends BaseField implements OnInit {
  options: PaymentOptionBox[] = [];
  cashFormRef: FormControl = new FormControl();
  paymentOptionRef = this.fb.group({
    payment_option: this.fb.array([])
  });
  productId: number;
  @Input() integrationPage = false;

  constructor(
    private fetchPaymentOptionsForSeller: FetchPaymentOptionsForSellerService,
    private getProductGroups: FetchPAymentGroupForSeller,
    private cdr: ChangeDetectorRef, private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private auth: AuthService,
    private basicStepOneTemplateService: BasicStepOneTemplateService
  ) {
    super();
  }

  get payment_option() {
    return this.paymentOptionRef.get('payment_option') as FormArray;
  }
  async ngOnInit() {
    const business = await this.auth.getBusiness();
    this.fetchPaymentOptionsForSeller.setParams({ id: business["id"], isSeller: true })
    this.options = await this.fetchPaymentOptionsForSeller.make();
    this.activatedRoute.params.subscribe(async params => {
      this.productId = params['id'];
    });
    this.options.map((option: any) => option.isSelected = true);
    //editmode
    let indx = 0;
    if(this.productId) {
      this.getProductGroups.setParams({productId:this.productId})
      const productGroups:{name:string}[] = await this.getProductGroups.make();
      const productGroupsArray = productGroups.map(group => group.name);
      for(let option of this.options){
        if(productGroupsArray.includes(option.name)){
          this.payment_option.push(new FormControl(true));
        }else{
          this.payment_option.push(new FormControl(false));
        }
        indx++;
      }

    }else if(this.integrationPage){
      // newproductmode
      for (let option of this.options) {
        this.payment_option.push(new FormControl(option.enabledDirectPay));
        indx++;
      }
    }else{
      // newproductmode
      for (let option of this.options) {
        this.payment_option.push(new FormControl(true));
        indx++;
      }
    }
    this.handleOutput();
    this.paymentOptionRef.valueChanges.subscribe(value => {
      this.handleOutput();
      this.cdr.detectChanges();
    });

    this.formControlRef.valueChanges.subscribe(value => {
      if (!value || value.payment_options || !value[0] || !value[0].id || typeof this.formControlRef.value == 'string') {
        this.handleOutput();
        this.cdr.detectChanges();
      }
    });

    this.cdr.detectChanges();

    this.basicStepOneTemplateService.paymentOptionsEmitter.subscribe((data: PaymentOptionBox[] | null) => {
      if(!data) return
      this.externalHandleOutput(data);
    })
  }

  handleOutput() {
    let outputobject: any = [];
    for (let i = 0; i < this.payment_option.value.length; i++) {
      if (this.payment_option.value[i]) {
        outputobject.push(this.options[i]);
      }
    }
    this.formControlRef.setValue(outputobject);
  }

  changePaymentOptionSelectionValue(checked: boolean, i: number){
    this.options[i].isSelected = checked
  }

  // note handleOutput depend on this.option order so do not change it.
  externalHandleOutput(savedPayments: PaymentOptionBox[]) {
    let outPut: PaymentOptionBox[] = [];
    for (let i = 0; i < this.options.length; i++) {
      const option = this.options[i];
      const optionInSavedPayments = savedPayments.find((savedOption) => {
        return savedOption.id == option.id;
      });

      if (!optionInSavedPayments) option.isSelected = false;
      this.payment_option.value[i] = option.isSelected;

      if (this.payment_option.value[i]) {
        outPut.push(this.options[i]);
      }

      this.formControlRef.setValue(outPut);
    }
  }

  convertControl(control: AbstractControl) {
    return control as FormControl;
  }
  translateOption(option: any) {
    if (localStorage.getItem("language") === "ar") {
      return option.nameAr;
    } else {
      return option.name;
    }
  }
}
