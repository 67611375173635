import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import * as _moment from 'moment';
import { MandatoryFieldsService } from '../../../services/mandatory-fields.service';
import { BaseField } from '../../baseField';
import moment from 'moment';

export const MY_CUSTOM_FORMATS = {
  parseInput: 'LL LT',
  fullPickerInput: 'LL LT',
  datePickerInput: 'LL',
  timePickerInput: 'LT',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY',
};
@Component({
  selector: 'app-datetime-range',
  templateUrl: './datetime-range.component.html',
  styleUrls: ['./datetime-range.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatetimeRangeComponent extends BaseField implements OnInit {
  public dateTimeRange: Date[] = [];
  public nextHour: Date;
  public startAt: Date;
  mandatoryDateRanges: string[] = ['Event Duration'];
  @Input() formControlRef: FormControl = new FormControl();
  @Input() mandatory: boolean = false;
  @Input() dateTimeWithHoursOnly: boolean = false;

  constructor(private mandatoryFieldsService: MandatoryFieldsService) {
    super();
  }

  ngOnInit() {
    this.mandatory = this.mandatoryFieldsService.isMandatory(this.mandatoryDateRanges, this.fieldObject.fieldName) || false;
    if(this.dateTimeWithHoursOnly){
      this.nextHour = moment().startOf('hour').add(1, 'hour').toDate();
      this.startAt = this.nextHour;
    }

    this.checkAndMarkTouched();

    //
  }

  checkAndMarkTouched() {
    if (this.formControlRef.errors) {
      this.formControlRef.markAsTouched();
    }
  }

  handleChange(event: any) {
    const selectedDate = moment(event.value).startOf('hour').toDate();
    if(this.dateTimeWithHoursOnly && selectedDate){
      this.startAt = moment(selectedDate).toDate();
      return this.formControlRef.setValue(selectedDate);
    }
    return this.formControlRef.setValue(event.value);
  }

  translateFieldName(fieldObject: any) {
    if (localStorage.getItem("language") === "ar") {
      return fieldObject.fieldNameAr;
    } else {
      return fieldObject.fieldName;
    }
  }
}
